<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-col>
            <product-concept-create type="track"/>
          </b-col>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: 'TrackConceptCreate',
    components: {
      'product-concept-create': () => import('@/views/ProductConceptCreate')
    },
    props: {
    },
    data () {
      return {
       
      }
    },
    watch: {

    },
    methods: {
     
    },
    computed: {

    },
    mounted () {

    }
  }
</script>

<style scoped>

</style>
